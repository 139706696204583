import React from 'react';
import PropTypes from 'prop-types';
import { range, round } from 'lodash';
import classNames from 'classnames';
import { getDataAttributes, getAriaAttributes } from '../utilities';

import style from './loading-spinner.scss';

const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
} as const;

const SIZES = {
  SMALL: 20,
  MEDIUM: 32,
  LARGE: 40,
};

// We should make sure that the circles always have a
// width and height that are a round number. Browsers
// struggle to give good border-radii to elements with
// non-integer dimensions.
const getCirclePxSize = (size: number) => round(size * 0.15);

type Props = {
  size?: number;
  theme?: (typeof THEMES)[keyof typeof THEMES];
};

const LoadingSpinner = ({
  size = SIZES.SMALL,
  theme = THEMES.LIGHT,
  ...props
}: Props) => {
  const circlePxSize = getCirclePxSize(size);

  const renderCircle = (n: number) => {
    const classes = classNames(style.circleWrapper, style[`circle${n}`], {
      [style.dark]: theme === THEMES.DARK,
    });

    return (
      <div key={n} className={classes}>
        <span
          className={style.circle}
          style={{
            width: `${circlePxSize}px`,
            height: `${circlePxSize}px`,
          }}
        />
      </div>
    );
  };

  return (
    <div
      className={style.wrapper}
      style={{ width: size, height: size }}
      aria-label="loading"
      {...getDataAttributes(props)}
      {...getAriaAttributes(props)}
    >
      {range(1, 13).map(renderCircle)}
    </div>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  theme: PropTypes.oneOf([THEMES.LIGHT, THEMES.DARK]),
};

LoadingSpinner.THEMES = THEMES;
LoadingSpinner.SIZES = SIZES;

export default LoadingSpinner;
