import { useEffect } from 'react';
import { intercom, redirect, getWindow } from '@Lib/global';

// Deprecated: use `useChatToCustomerSupport` hook instead.
const chatToCustomerSupport = (initialMessage, skipIntercom) => {
  if (!!getWindow().Intercom) {
    // We might want to skip Intercom and not fire it, if we are firing it in another way
    // for example: if we have a bot that fires based on a CSS class, but we still want to have
    // the fallback if Intercom isn't loaded at all
    if (!skipIntercom) {
      intercom('showMessages');
    }
  } else {
    redirect(`mailto:support@geckoboard.com?subject=${initialMessage}`);
  }
};

const useHideIntercomBubble = () => {
  useEffect(() => {
    intercom('update', { hide_default_launcher: true });
  }, []);

  return null;
};

const useMoveIntercombBubbleRight = () => {
  useEffect(() => {
    intercom('update', { alignment: 'right' });

    return () => intercom('update', { alignment: 'left' });
  }, []);

  return null;
};

export {
  chatToCustomerSupport,
  useHideIntercomBubble,
  useMoveIntercombBubbleRight,
};
