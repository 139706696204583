import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Blob from '@UI/blob';
import Button from '@UI/button';
import FormattedMessage from '@Lib/i18n';
import fetchJSON from '../../universal/fetch';
import LoginLayout from '../login-layout';

import styles from './password-reset-page-styles.scss';

const ERROR_MESSAGES = {
  ErrEmailRequired: 'login.passwordReset.error.emailRequired',
  ErrPasswordResetFailed: 'login.passwordReset.error.defaultError',
};

const PasswordResetPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const onFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const email = e.target.elements.email.value;

      if (!email) {
        setErrorCode('ErrEmailRequired');
        setLoading(false);
        return;
      }

      await fetchJSON('/password_resets', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify({ user: { email } }),
      });
      history.push('/login?notice=NoticePasswordResetSent');
    } catch (error) {
      const { type = 'ErrFetchError' } = error;
      setErrorCode(type);
      setLoading(false);
    }
  };
  return (
    <LoginLayout
      title="Reset your password"
      heading="Forgot your password?"
      subheading="Enter the email address you used to sign up."
      footer={
        <p style={{ marginTop: '4rem' }}>
          Here by mistake?{' '}
          <Link to="/login">
            <FormattedMessage id="login.passwordReset.signIn" />
          </Link>
        </p>
      }
    >
      <div className={styles.formHeader}>
        {errorCode && (
          <div className={styles.errorMessage}>
            <FormattedMessage id={ERROR_MESSAGES[errorCode]} />
          </div>
        )}
      </div>
      <div className={styles.form}>
        <form onSubmit={onFormSubmit}>
          <div className={styles.formField}>
            <input
              type="email"
              placeholder="Email address"
              name="email"
              aria-label="email"
              className={styles.emailInput}
              autoFocus
              required
            />
            <div className={styles.blob}>
              <Blob version={Blob.VERSIONS.FIRST} />
            </div>
          </div>
          <Button
            disabled={loading}
            loading={loading}
            stretched
            type={Button.TYPES.SUBMIT}
          >
            <FormattedMessage id="login.passwordReset.submitButton" />
          </Button>
        </form>
      </div>
    </LoginLayout>
  );
};

export default PasswordResetPage;
