import '../public-path';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router';
import { history } from '../router';
import { ConfiguredI18NProvider } from '../lib/i18n';
/*
 * Add Intl support for old browsers. 'intl' handles all the Polyfill
 * logic internally so we just need to import it along with the data
 * for locales we want to support. When all browsers that we want
 * to support have native Intl support, we can delete this.
 */
import 'intl';

import SSOLoginPage from './sso-login-page/sso-login-page-component';
import StaticLoginPage from './static-login/static-login-page-component';
import PasswordResetPage from './password-reset/password-reset-page-component';

ReactDOM.render(
  <ConfiguredI18NProvider>
    <Router history={history}>
      <Switch>
        <Route path="/login">
          <StaticLoginPage />
        </Route>
        <Route path="/sso">
          <SSOLoginPage />
        </Route>
        <Route path="/password_resets">
          <PasswordResetPage />
        </Route>
      </Switch>
    </Router>
  </ConfiguredI18NProvider>,
  document.getElementById('app'),
);
