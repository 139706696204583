import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import translations from '../../../content/default.json';

// Gives the child tree access to the translation dictionary
const ConfiguredI18NProviderComponent = ({ children }) => (
  <IntlProvider locale="en" messages={translations}>
    {children}
  </IntlProvider>
);

ConfiguredI18NProviderComponent.propTypes = {
  children: PropTypes.node,
};

export default ConfiguredI18NProviderComponent;
