import React from 'react';
import { createBrowserHistory } from 'history';
import { splitQueryString, joinQueryParams } from './lib/url';

const history = createBrowserHistory();

/**
 * navigate
 *
 * Navigate using client side routing
 *
 * @param {string} path
 */
const navigate = (path) => {
  history.push(path);
};

/**
 * getQueryParams
 *
 * Returns values in the query string as key value pairs
 * note: This is currently imported and used by Gecko-JS
 */
const getQueryParams = () => {
  const { search } = history.location;
  return splitQueryString(search);
};

/**
 * getQueryParam
 *
 * For a given key returns the value in the query string
 *
 * @param {string} param
 */
const getQueryParam = (param) => {
  const { search } = history.location;
  const queryParams = splitQueryString(search);
  return queryParams[param];
};

/**
 * setQueryParam
 *
 * Adds an item to the query string
 *
 * @param {string} param
 * @param {string} value
 */
const setQueryParam = (param, value) => {
  const { pathname, search } = history.location;
  const queryParams = splitQueryString(search);

  queryParams[param] = value;

  history.replace({
    pathname,
    search: joinQueryParams(queryParams),
  });
};

/**
 * removeQueryParam
 *
 * Removes an item from the query string
 *
 * @param {string} param
 */
const removeQueryParam = (param) => {
  const { pathname, search } = history.location;
  const queryParams = splitQueryString(search);

  delete queryParams[param];

  history.replace({
    pathname,
    search: joinQueryParams(queryParams),
  });
};

const router = {
  navigate,
  goBack: () => history.goBack(),
  getHistory: () => history,
  getQueryParams,
  getQueryParam,
  setQueryParam,
  removeQueryParam,
};

// TODO stop exporting `navigate` directly, it makes spying difficult
export { navigate, router, history, useHistory, withHistory };

function useHistory() {
  return history;
}

function withHistory(Component) {
  return (props) => {
    return <Component {...props} history={history} />;
  };
}
