import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getDataAttributes, getAriaAttributes } from '../utilities';
import Icon from '../icon';
import LoadingSpinner from '../loading-spinner';

import style from './button.scss';

/**
 * Possible button sizes.
 * @readonly
 * @static
 * @enum {string}
 */
const SIZES = {
  EXTRA_SMALL: 'extra-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const;

/**
 * Possible button intents.
 * @readonly
 * @static
 * @enum {string}
 */
const INTENT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  NEGATIVE: 'negative',
  LINK: 'link',
} as const;

/**
 * Possible button types.
 * @readonly
 * @static
 * @enum {string}
 */
const TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
} as const;

const mapSizeToLoadingSpinnerSizes = {
  [SIZES.EXTRA_SMALL]: 12,
  [SIZES.SMALL]: 12,
  [SIZES.MEDIUM]: 16,
  [SIZES.LARGE]: 18,
};

const mapIntentToLoadingSpinnerTheme = {
  [INTENT.PRIMARY]: LoadingSpinner.THEMES.DARK,
  [INTENT.SECONDARY]: LoadingSpinner.THEMES.DARK,
  [INTENT.NEGATIVE]: LoadingSpinner.THEMES.LIGHT,
  [INTENT.LINK]: LoadingSpinner.THEMES.DARK,
};

type Props = {
  checked?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  download?: string;
  href?: string;
  icon?: string;
  intent?: (typeof INTENT)[keyof typeof INTENT];
  loading?: boolean;
  role?: string;
  size?: (typeof SIZES)[keyof typeof SIZES];
  stretched?: boolean;
  target?: string;
  title?: string;
  type?: (typeof TYPES)[keyof typeof TYPES];
  // eslint-disable-next-line @typescript-eslint/ban-types -- Disabled by codemod when new recommended rulesets introduced
  onBlur?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types -- Disabled by codemod when new recommended rulesets introduced
  onClick?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types -- Disabled by codemod when new recommended rulesets introduced
  onFocus?: Function;
} & React.AriaAttributes &
  Record<`data-${string}`, unknown>;

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (props, ref) => {
    const {
      href,
      icon,
      intent = INTENT.PRIMARY,
      disabled,
      download,
      loading,
      checked,
      children,
      onClick,
      onFocus,
      onBlur,
      size = SIZES.MEDIUM,
      stretched,
      target,
      title,
      type = TYPES.BUTTON,
      role,
    } = props;

    const Tag = href ? 'a' : 'button';
    const hasIcon = !!icon && !loading;

    const classes = classNames(
      style.Button,
      style[`size-${size}`],
      style[`intent-${intent}`],
      {
        [style.stretched]: stretched,
        [style.loading]: loading,
        [style.hasIcon]: hasIcon,
      },
    );

    return (
      <Tag
        // @ts-expect-error ref type get confused between button and a
        ref={ref}
        className={classes}
        disabled={disabled || loading}
        download={download}
        href={href}
        onClick={(e) => onClick && onClick(e)}
        onFocus={(e) => onFocus && onFocus(e)}
        onBlur={(e) => onBlur && onBlur(e)}
        target={target}
        title={title}
        type={type}
        role={role}
        checked={checked}
        aria-checked={checked !== undefined ? String(checked) : undefined}
        {...getDataAttributes(props)}
        {...getAriaAttributes(props)}
      >
        <TransitionGroup className={style.spinnerContainer}>
          {loading && (
            <CSSTransition
              classNames={{
                enter: style.spinnerTransitionEnter,
                enterActive: style.spinnerTransitionEnterActive,
                exit: style.spinnerTransitionExit,
                exitActive: style.spinnerTransitionExitActive,
              }}
              timeout={200}
            >
              <div className={style.spinner}>
                <LoadingSpinner
                  aria-hidden
                  size={mapSizeToLoadingSpinnerSizes[size]}
                  theme={mapIntentToLoadingSpinnerTheme[intent]}
                />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        {hasIcon && (
          <span
            className={classNames(style.icon, {
              [style.iconNoChildren]: !children,
            })}
          >
            <Icon name={icon} />
          </span>
        )}
        {children}
      </Tag>
    );
  },
);

Button.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  download: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  intent: PropTypes.oneOf([
    INTENT.PRIMARY,
    INTENT.SECONDARY,
    INTENT.NEGATIVE,
    INTENT.LINK,
  ]),
  loading: PropTypes.bool,
  role: PropTypes.string,
  size: PropTypes.oneOf([
    SIZES.EXTRA_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
  ]),
  stretched: PropTypes.bool,
  target: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf([TYPES.BUTTON, TYPES.SUBMIT, TYPES.RESET]),
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
};

// React.forwardRef wipes the component display
// name so we need to manually set it.
Button.displayName = 'Button';

const ButtonWithEnums = Object.assign(Button, { SIZES, INTENT, TYPES });

export default ButtonWithEnums;
