// delay is optional
const redirect = (target, delay) => {
  if (delay) {
    setTimeout(() => {
      window.location.href = target;
    }, delay);
  } else {
    window.location.href = target;
  }
};

const hash = () => {
  return window.location.hash;
};

const getWindow = () => window;

const getNavigator = () => navigator;

const getDocumentElement = () => window.document.documentElement;

const getViewportDimensions = () => ({
  width: window.innerWidth || document.documentElement.clientWidth,
  height: window.innerHeight || document.documentElement.clientHeight,
});

const intercom = (...args) => {
  try {
    window.Intercom(...args); // eslint-disable-line new-cap
  } catch (e) {
    // swallow error here as Intercom can fail to load but should not block our
    // application to load completely
  }
};

const globalClearTimeout = (...args) => {
  return window.clearTimeout(...args);
};

const getNodeEnv = () => process.env.NODE_ENV;

export {
  getNodeEnv,
  getWindow,
  getNavigator,
  getDocumentElement,
  getViewportDimensions,
  globalClearTimeout,
  hash,
  intercom,
  redirect,
};
